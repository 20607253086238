import React, { useEffect, useState } from 'react';

const CategoriesComponent = () => {
    const [categories, setCategories] = useState([]);
    const [newCategory, setNewCategory] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCategories, setTotalCategories] = useState(0);
    const [expandedCategory, setExpandedCategory] = useState(null); // Track expanded category
    const [categoryArticles, setCategoryArticles] = useState({}); // Store articles for each category
    const [selectedArticle, setSelectedArticle] = useState(null); // Tracks which article is clicked
    const perPage = 10; // Adjust per page items
  
    // Fetch categories
    const fetchCategories = async (page) => {
      const offset = (page - 1) * perPage;
      try {
        const response = await fetch(`https://wikiguessr-6d5a3c1aebf6.herokuapp.com/api/categories?limit=${perPage}&offset=${offset}`);
        const data = await response.json();
        setCategories(data.categories || []);
        setTotalCategories(data.totalCategories || 0);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    const handleArticleClick = (categoryId, article, index) => {
      if (selectedArticle === index) {
        setSelectedArticle(null); // Deselect if the same article is clicked again
      } else {
        setSelectedArticle(index); // Select the article
      }
    };
    
    const handleRemoveArticleFromCategory = async (categoryId, articleId) => {
      try {
        const response = await fetch(`https://wikiguessr-6d5a3c1aebf6.herokuapp.com/api/remove-article-from-category`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            categoryId,
            articleId, // Send articleId instead of articleTitle
          }),
        });
    
        if (response.ok) {
          // Optionally update the UI to remove the article from the category
          alert(`Article removed from category.`);
    
          // Optionally, update the state to remove the article from the UI without refreshing
          setCategoryArticles((prev) => {
            // Ensure the category exists in the state before filtering
            if (!prev[categoryId]) {
              return prev; // If the category doesn't exist, return the previous state unchanged
            }
    
            return {
              ...prev,
              [categoryId]: prev[categoryId].filter((art) => art.id !== articleId),
            };
          });
    
        } else {
          alert('Failed to remove article from category.');
        }
      } catch (error) {
        console.error('Error removing article:', error);
        alert('Error removing article from category.');
      }
    };
    
    
  
    // Fetch articles for a category
    const fetchCategoryArticles = async (categoryName) => {
      try {
        const response = await fetch(`https://wikiguessr-6d5a3c1aebf6.herokuapp.com/api/categories/${categoryName}/articles`);
        const data = await response.json();
        setCategoryArticles((prevArticles) => ({
          ...prevArticles,
          [categoryName]: data.articles || [],
        }));
      } catch (error) {
        console.error('Error fetching articles for category:', error);
      }
    };
  
    // Handle expanding a category
    const toggleCategoryExpand = (categoryName) => {
      if (expandedCategory === categoryName) {
        setExpandedCategory(null); // Collapse if already expanded
      } else {
        setExpandedCategory(categoryName); // Expand the new category
        fetchCategoryArticles(categoryName); // Fetch articles when expanding
      }
    };
  
    // Add a new article to the category
    const handleAddArticle = async (categoryId) => {
      const articleTitle = prompt('Enter the article title:');
      if (articleTitle) {
        try {
          const response = await fetch(`https://wikiguessr-6d5a3c1aebf6.herokuapp.com/api/categories/${categoryId}/articles`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ articleTitle }),
          });
  
          const data = await response.json();
  
          if (data.success) {
            alert('Article added to category successfully');
            // Refetch articles to update the list after adding
            fetchCategoryArticles(expandedCategory);
          } else {
            alert('Error adding article to category');
          }
        } catch (error) {
          console.error('Error adding article to category:', error);
        }
      }
    };
  
    // Add a new category
    const addCategory = async () => {
      if (newCategory.trim()) {
        try {
          const response = await fetch('https://wikiguessr-6d5a3c1aebf6.herokuapp.com/api/categories', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name: newCategory }),
          });
  
          const data = await response.json();
  
          if (data.success) {
            setNewCategory(''); // Clear input
            fetchCategories(currentPage); // Refetch categories
          } else {
            console.error('Error adding category:', data.message);
          }
        } catch (error) {
          console.error('Error adding category:', error);
        }
      }
    };
  
    // Remove a category
    const removeCategory = async (categoryId) => {
      try {
        const response = await fetch(`https://wikiguessr-6d5a3c1aebf6.herokuapp.com/api/categories/${categoryId}`, {
          method: 'DELETE',
        });
  
        const data = await response.json();
  
        if (data.success) {
          fetchCategories(currentPage); // Refetch categories
        } else {
          console.error('Error deleting category:', data.message);
        }
      } catch (error) {
        console.error('Error deleting category:', error);
      }
    };
  
    // Load categories when component mounts or currentPage changes
    useEffect(() => {
      fetchCategories(currentPage);
    }, [currentPage]);
  
    return (
      <div className="categories-component">
        <h3>Categories</h3>
        <div className="add-category">
          <input
            type="text"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            placeholder="Enter category name"
          />
          <button onClick={addCategory}>Add Category</button>
        </div>
        <ul>
          {categories.length > 0 ? (
            categories.map((category) => (
              <li
                key={category.id}
                onClick={() => toggleCategoryExpand(category.category_name)}
                className={expandedCategory === category.category_name ? 'expanded' : ''}
              >
                <p><strong>Category:</strong> {category.category_name}</p>
                {expandedCategory === category.category_name && (
                  <ul>
                    <button
                      className="add-article-btn"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent event bubbling to parent
                        handleAddArticle(category.id);
                      }}
                    >
                      Add Article to {category.category_name}
                    </button>
                    {categoryArticles[category.category_name] ? (
                    categoryArticles[category.category_name].map((article, index) => (
                        <li
                        key={index}
                        onClick={(e) => {
                            e.stopPropagation(); // Prevent event bubbling to parent
                            handleArticleClick(category.id, article, index); // Toggle remove button
                        }}
                        >
                        {article.title} {/* Access a specific property, e.g., title */}
                        {selectedArticle === index && (
                            <button
                            className="remove-article-btn"
                            onClick={(e) => {
                                e.stopPropagation(); // Prevent event bubbling to parent
                                handleRemoveArticleFromCategory(category.id, article.id); // Make sure to pass article ID
                                console.log(article);
                            }}
                            >
                            Remove
                            </button>
                        )}
                        </li>
                    ))
                    ) : (
                    <li>Loading articles...</li>
                    )}
                  </ul>
                )}
                <button
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent event bubbling to parent
                    removeCategory(category.id);
                  }}
                >
                  Remove
                </button>
              </li>
            ))
          ) : (
            <p>No categories found.</p>
          )}
        </ul>
    
        <div className="pagination-controls">
          <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
            Previous
          </button>
          <span>Page {currentPage} of {Math.ceil(totalCategories / perPage)}</span>
          <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === Math.ceil(totalCategories / perPage)}>
            Next
          </button>
        </div>
      </div>
    );
  }    

export default CategoriesComponent;
