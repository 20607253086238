import React, { useCallback, useEffect, useState } from 'react';
import './App.css'; // Import the CSS file
import io from 'socket.io-client';
import MatchHistoryComponent from './MatchHistoryComponent';
import ArticlesComponent from './ArticlesComponent';
import CategoriesComponent from './CategoriesComponent';
import UsersComponent from './UsersComponent';
import LobbiesComponent from './LobbiesComponent';
import FetchArticle from './FetchArticle';

// Update the Socket.IO client connection URL
const socket = io('https://wikiguessr-6d5a3c1aebf6.herokuapp.com'); // Adjust the URL as necessary



const App = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [articleContent, setArticleContent] = useState(null); // Keep articleContent client-side
  const [startArticle, setStartArticle] = useState(null);
  const [endArticle, setEndArticle] = useState(null);
  const [startCategory, setStartCategory] = useState([]);
  const [endCategory, setEndCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]); // Keep history client-side
  const [time, setTime] = useState({ minutes: 0, seconds: 0 });
  const [isRunning, setIsRunning] = useState(false);
  const [gameCompleted, setGameCompleted] = useState(false);
  const [mainMenu, setmainMenu] = useState(true);
  const [gameDifficulty, setGameDifficulty] = useState('');
  const [scoreboard, setScoreboard] = useState([]); // To store the scoreboard data
  const [winner, setWinner] = useState('');
  const [categories, setCategories] = useState([]); // Store categories
  const [selectedCategory, setSelectedCategory] = useState(''); // Store selected category
  const [loggedIn, setLoggedIn] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [email, setEmail] = useState('')
  const [isAdmin, setIsAdmin] = useState(null); // Track if the user is an admin
  const [showAdminPanel, setShowAdminPanel] = useState(false); // Track if the admin panel is visible
  const [selectedTool, setSelectedTool] = useState('users');

  // New state variables for lobby functionality
  const [lobbyId, setLobbyId] = useState('');
  const [isHost, setIsHost] = useState(false);
  const [username, setUsername] =  useState('');
  const [openLobbies, setOpenLobbies] = useState([]);
  const [lobbyPlayers, setLobbyPlayers] = useState([]);


  
// Show login form when login button is clicked
const handleLoginClick = () => {
  handleLeaveLobby();
  setUsername('');
  setShowLoginForm(true);
  setShowRegisterForm(false);
  
  setmainMenu(false);
};

const toggleAdminPanel = async () => {
  // Re-check authentication and admin status
  await checkUserAuthentication();
  
  // If the user is an admin, toggle the admin panel
  if (isAdmin) {
    setShowAdminPanel(!showAdminPanel);
  } else {
    alert("Access denied. Admins only.");
  }
};

document.addEventListener('keydown', function(event) {
  if (event.ctrlKey && event.key === 'f') {
    // Prevent default Ctrl + F behavior
    event.preventDefault();


    handleGiveUp();

  }
});

// Function to handle login (just for demonstration)
// Function to handle login (updated for HttpOnly cookie usage)
const handleLogin = async (e) => {
  e.preventDefault();
  try {
    const response = await fetch('https://wikiguessr-6d5a3c1aebf6.herokuapp.com/login', {
      method: 'POST',
      credentials: 'include',  // Include cookies in the request
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });

    const data = await response.json();
    
    if (data.success) {
      // Since the token is stored in the cookie, you no longer need to manually handle it.
      // Instead, manage login state based on response success
      await checkUserAuthentication();
      setLoggedIn(true);
      setShowLoginForm(false);
      setmainMenu(true);
      setError('');
      
    } else {
      setError('Invalid username or password');
      setUsername('');  // Clear username field
      setPassword('');  // Clear password field
    }
  } catch (error) {
    setError('An error occurred while logging in');
  }
};


  // Handle register form submit
  const handleRegister = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    try {
      const response = await fetch('https://wikiguessr-6d5a3c1aebf6.herokuapp.com/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, email, password }),
      });
      const data = await response.json();
      if (data.success) {
        setSuccessMessage('Registration successful! You can now log in.');
        setShowRegisterForm(false);
        setError('');
        setUsername('');
        setEmail('');
        setPassword('');
        setConfirmPassword('');
        setShowLoginForm(true);
      } else {
        setError(data.message || 'Registration failed');
      }
    } catch (error) {
      setError('An error occurred during registration');
    }
  };

  const handleRegisterClick = () => {
    handleLeaveLobby();
    setShowRegisterForm(true);
    setShowLoginForm(false);
    setmainMenu(false);
  };


  const handleLogout = async () => {
    try {
      const response = await fetch('https://wikiguessr-6d5a3c1aebf6.herokuapp.com/logout', {
        method: 'POST',
        credentials: 'include',  // Include cookies in the request
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      const data = await response.json();
      if (data.success) {
        // Clear any local state related to the user
        setUsername('');
        setShowAdminPanel(false);
        setLoggedIn(false);
        setIsAdmin(false);
        handleGiveUp();
        alert('You have been logged out successfully.');
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };
  

const handleCategorySelect = (category) => {
  setSelectedCategory(category);

  // Emit the selected category to the server (if required)
  socket.emit('updateCategory', { lobbyId, category });
  
  console.log('Selected category:', category);
};

const checkUserAuthentication = async () => {
  try {
    const response = await fetch('https://wikiguessr-6d5a3c1aebf6.herokuapp.com/verify-token', {
      method: 'GET',
      credentials: 'include',  // Include the cookie in the request
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      // Handle the error (401 Unauthorized or any other error status)
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    if (data.success) {
      setUsername(data.username);  // Set the logged-in username in state
      setIsAdmin(data.admin);       // Set admin status
      setLoggedIn(true);            // Update the logged-in state
    } else {
      setLoggedIn(false);           // If token is invalid, reset logged-in state
      setIsAdmin(false);            // Reset admin state
    }
  } catch (error) {
    console.error('Error checking user authentication', error);
    setLoggedIn(false);
    setIsAdmin(false);              // Reset admin state on error
  }
};

useEffect(() => {
  checkUserAuthentication();
}, []);  // This effect runs once when the component mounts (or when the page is refreshed)


  useEffect(() => {
    // Fetch categories from the backend
    const fetchCategories = async () => {
      try {
        const response = await fetch('https://wikiguessr-6d5a3c1aebf6.herokuapp.com/categories');
        const data = await response.json();
        const validCategories = data.categories.filter(category => category && category.trim() !== '');
        setCategories(validCategories); // Store the categories in state
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
  
    fetchCategories();
  }, []);

  
  // Timer effect: increments time every second when the timer is running
  useEffect(() => {
    let timerInterval = null;
    if (isRunning) {
      timerInterval = setInterval(() => {
        setTime((prevTime) => {
          let { minutes, seconds } = prevTime;
          seconds += 1;
          if (seconds >= 60) {
            seconds = 0;
            minutes += 1;
          }
          return { minutes, seconds };
        });
      }, 1000);
    } else if (!isRunning && timerInterval) {
      clearInterval(timerInterval);
    }
    return () => clearInterval(timerInterval); // Clean up interval on unmount or stop
  }, [isRunning]);

  // Scroll to the top of the article when article content changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [articleContent]);

  // Socket.IO event listeners
  useEffect(() => {


    

    // Request the list of open lobbies when the component mounts
    socket.emit('getOpenLobbies');

    // Listen for updates to the lobby list
    socket.on('openLobbies', (lobbies) => {
      setOpenLobbies(lobbies);
    });

    socket.on('lobbyListUpdated', (lobbies) => {
      setOpenLobbies(lobbies);
    });



    

    // Listen for game state updates (this should not update the articleContent)
    socket.on('gameStateUpdate', (gameState, selectedCategory) => {
      console.log('Game State:', gameState); // Debugging
      if (gameState) {
        setStartArticle(gameState.startArticle);
        setEndArticle(gameState.endArticle);
        setStartCategory(gameState.startCategory || []); // Set categories, or default to empty array
        setEndCategory(gameState.endCategory || []); // Set categories, or default to empty array
        setTime(gameState.time);
        setGameCompleted(gameState.gameCompleted);
        setIsRunning(gameState.isRunning);
        setmainMenu(gameState.mainMenu);
        setSelectedCategory(gameState.selectedCategory);

        console.log('Start Category:', gameState.startCategory); // Debugging
        console.log('End Category:', gameState.endCategory); // Debugging



        // Fetch the start article for the player
        if (!gameState.gameCompleted) {
          FetchArticle(gameState.startArticle).then((result) => {
            if (!result.error) {
              setArticleContent(result);
            }
          });
        }
      }
    });


    

    // Listen for game result (when a player wins)
    socket.on('gameResult', ({ winner, players }) => {
      setWinner(winner);
      setScoreboard(players);
      setIsRunning(false); // Stop the timer for all players
      setGameCompleted(true); // Mark the game as completed
    });

    // Listen for lobby players updates
    socket.on('lobbyPlayersUpdate', (players) => {
      setLobbyPlayers(players);
    });

    // Clean up the listeners on unmount
    return () => {
      socket.off('openLobbies');
      socket.off('lobbyListUpdated');
      socket.off('gameStateUpdate');
      socket.off('gameResult');
      socket.off('lobbyPlayersUpdate');
      socket.off('updateCategory');
    };
  }, []);

  // Handle creating a new lobby
  const handleCreateLobby = () => {
    if (!loggedIn) {
      setUsername('');
      const inputUsername = prompt('Enter your username:');
      if (!inputUsername) return;
      setUsername(inputUsername + ' (Guest)');
      socket.emit('createLobby', { username: inputUsername + ' (Guest)' }, ({ lobbyId }) => {
        setLobbyId(lobbyId);
        setIsHost(true);
      });
    } else {
      socket.emit('createLobby', { username }, ({ lobbyId }) => {
        setLobbyId(lobbyId);
        setIsHost(true);
      });
    }
  };

  // Handle joining a lobby
  const handleJoinLobby = (lobbyIdToJoin) => {
    if (!loggedIn) {
      setUsername('');
      const inputUsername = prompt('Enter your username:');
      if (!inputUsername) return;
      setUsername(inputUsername + ' (Guest)');
      socket.emit(
        'joinLobby',
        { lobbyId: lobbyIdToJoin, username: inputUsername + ' (Guest)' },
        (response) => {
          if (response.success) {
            setLobbyId(lobbyIdToJoin);
            setIsHost(false);
          } else {
            alert(response.message);
          }
        }
      );
    } else {
      socket.emit(
        'joinLobby',
        { lobbyId: lobbyIdToJoin, username },
        (response) => {
          if (response.success) {
            setLobbyId(lobbyIdToJoin);
            setIsHost(false);
          } else {
            alert(response.message);
          }
        }
      );
    }
  };

  // Handle leaving a lobby
  const handleLeaveLobby = () => {
    socket.emit('leaveLobby', { lobbyId });
    setLobbyId('');
    setIsHost(false);
    // Reset game state if needed
    setIsRunning(false);
    setTime({ minutes: 0, seconds: 0 });
    setHistory([]);
    setmainMenu(true);
    setStartArticle(null);
    setEndArticle(null);
    setArticleContent(null);
    setGameCompleted(false);
    setLobbyPlayers([]);
    setShowLoginForm(false);
    setShowRegisterForm(false);
  };

  // Handle game reset and leave the lobby
  const handleGiveUp = () => {
    socket.emit('leaveLobby', { lobbyId });
    setIsRunning(false); // Stop the timer
    setTime({ minutes: 0, seconds: 0 }); // Reset the timer
    setHistory([]); // Clear history
    setmainMenu(true); // Show the rules again
    setStartArticle(null); // Reset articles
    setEndArticle(null);
    setArticleContent(null); // Clear the article content
    setGameCompleted(false); // Reset game completion state
    handleLeaveLobby(); // Leave the lobby
  };

  // Handle starting the game
  const handleStartGameClick = async () => {
    if (!isHost) return; // Only the host can start the game
    setHistory([]); // Empty the history array
    setTime({ minutes: 0, seconds: 0 }); // Reset the timer
    setIsRunning(true); // Start the timer
    setGameCompleted(false); // Reset game completion state
    setmainMenu(false); // Hide rules when game starts
    socket.emit('startGame', { lobbyId, selectedCategory }); // Emit the startGame event to the server
  };


  // Handle searching for a Wikipedia article (no effect on start or end article)
  const handleSearch = async (event) => {
    event.preventDefault();
    if (!searchQuery) return; // If there's no search query, do nothing

    try {
      setLoading(true);
      const response = await fetch(
        `https://en.wikipedia.org/w/api.php?action=query&list=search&format=json&srsearch=${encodeURIComponent(
          searchQuery
        )}&origin=*`
      );
      const data = await response.json();

      if (data.query.search.length > 0) {
        const firstResultTitle = data.query.search[0].title;
        const result = await FetchArticle(firstResultTitle);
        if (!result.error) {
          setArticleContent(result); // Display search result article
        }
      } else {
        setArticleContent({
          title: 'No Results',
          content: 'No articles found for this search query.',
        });
      }
    } catch (error) {
      setArticleContent({
        title: 'Error',
        content: 'An error occurred while searching for the article.',
      });
    } finally {
      setLoading(false);
    }
  };

  
  
  
  
  
  
  

  // Handle link clicks and check if the end article has been reached
// Handle link clicks and check if the end article has been reached
const handleLinkClick = async (event) => {
  const link = event.target.closest('a');
  if (link) {
    event.preventDefault(); // Prevent default link behavior

    const linkUrl = link.href; // Get the full URL of the clicked link
    const linkText = link.textContent.trim(); // Get the text of the clicked link

    // Extract the article title from the URL (assuming Wikipedia link structure)
    let articleTitle = new URL(linkUrl).pathname.split('/').pop();
    const linkClick = link.href.split('/').pop(); // Extracts 'Provinces_and_territories_of_Canada'

    // Replace any underscores with spaces to format the article title correctly
    articleTitle = articleTitle.replace(/_/g, ' ');

    const result = await FetchArticle(decodeURIComponent(linkClick)); // Fetch article based on the article title from the URL

    if (!result.error) {
      setArticleContent(result); // Update article content with the link result

      // Update history
      const updatedHistory = [...history, linkText];
      setHistory(updatedHistory);

      // Emit the updated history to the server
      socket.emit('updatePlayerHistory', { lobbyId, playerName: username, history: updatedHistory });

      // Normalize strings for comparison
      const normalizeString = (str) => {
        return str
          .replace(/–|—|−/g, '-')  // Replace en dash, em dash, and minus sign with hyphen
          .replace(/%20/g, ' ')     // Replace encoded spaces with actual spaces if necessary
          .trim()                   // Remove leading/trailing spaces
          .toLowerCase();           // Convert to lowercase for case-insensitive comparison
      };

      // Check if the user has reached the end article
      if (normalizeString(articleTitle) === normalizeString(endArticle)) {
        socket.emit('playerWin', { lobbyId, playerName: username, history: updatedHistory });
        console.log('Player has reached the end article!', articleTitle, endArticle);
      }
    }
  }
};




  // Handle game difficulty selection
  const handleDifficultySelect = (difficulty) => {
    setGameDifficulty(difficulty); // Set the selected difficulty
  };



  const handleUsersClick = () => {
    setSelectedTool('users');
    // Here you can load users data, fetch from API, etc.
  };

  const handleCategoriesClick = () => {
    setSelectedTool('categories');
    // Fetch or load categories
  };

  const handleArticlesClick = () => {
    setSelectedTool('articles');
    // Fetch or load articles
  };

  const handleLobbiesClick = () => {
    setSelectedTool('lobbies');
    // Fetch or load lobbies
  };
  const handleMatchHistoryClick = () => {
    setSelectedTool('match_history');
    // Here you can load users data, fetch from API, etc.
  };



  return (
    <div className="App">
      <header className="header">
        <h1>Wikiguessr</h1>
        <div className="menu-right">
  {loggedIn ? (
    <>
      {/* Logout button for logged-in users */}
      <button onClick={handleLogout}>Logout</button>

      {isAdmin === 1 ? (
  <button onClick={toggleAdminPanel}>
    Admin
  </button>
) : null}
    </>
  ) : (
    <>
      {/* These buttons should be visible when not logged in */}
      <button onClick={handleLeaveLobby}>Home</button>
      <button onClick={handleLoginClick}>Login</button>
      <button onClick={handleRegisterClick}>Register</button>
    </>
  )}
</div>
        
        <form onSubmit={handleSearch} className="search-form">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search Wikipedia"
          />
          <button type="submit">Search</button>
        </form>
      </header>
      {showRegisterForm && (
        <div className="register-form">
          <form onSubmit={handleRegister}>
            <h2>Register</h2>
            <div>
              <label>Username:</label>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div>
              <label>Email:</label> {/* New email input field */}
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div>
              <label>Password:</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div>
              <label>Confirm Password:</label>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            {error && <p className="error">{error}</p>}
            <button type="submit">Register</button>
            {successMessage && <p className="success">{successMessage}</p>}
          </form>
        </div>
      )}
      {showAdminPanel && (
        <div className="admin-panel">
          <h2>Admin Panel</h2>
          <div className="admin-tools">
            <button onClick={handleUsersClick}>Users</button>
            <button onClick={handleCategoriesClick}>Categories</button>
            <button onClick={handleArticlesClick}>Articles</button>
            <button onClick={handleLobbiesClick}>Lobbies</button>
            <button onClick={handleMatchHistoryClick}>Match History</button>
          </div>

          {/* Placeholder for where admin data might be displayed */}
          <div className="admin-content">
            {/* You can conditionally render the content based on the selected tool */}
            {selectedTool === 'users' && <UsersComponent />}
            {selectedTool === 'categories' && <CategoriesComponent />}
            {selectedTool === 'articles' && <ArticlesComponent />}
            {selectedTool === 'lobbies' && <LobbiesComponent />}
            {selectedTool === 'match_history' && <MatchHistoryComponent />}
          </div>
        </div>
      )}
      {/* Show login form when user clicks login */}
      {showLoginForm && (
        <div className="login-form">
          <form onSubmit={handleLogin}>
            <h2>Login</h2>
            <div>
              <label>Username:</label>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div>
              <label>Password:</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            {error && <p className="error">{error}</p>}
            <button type="submit">Login</button>
          </form>
        </div>
      )}
      <div className="content">
        {gameCompleted ? (
          // Render scoreboard when game is completed
          <div className="scoreboard">
            <h3>Winner: {winner}</h3>
            <table>
              <thead>
                <tr>
                  <th>Player</th>
                  <th>History</th>
                </tr>
              </thead>
              <tbody>
                {scoreboard.map((player, index) => (
                  <tr key={index}>
                    <td>{player.username}</td>
                    <td>
                      <ul>
                      <li><strong>{startArticle}</strong></li>
                        {player.history.map((click, i) => (
                          <li key={i}>{click}</li>
                        ))}
                        {/* Show endArticle only if player is the winner */}
                        {player.username === winner && <li><strong>{endArticle}</strong></li>}
                      </ul>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* Add Return to Home Button */}
            <button onClick={handleGiveUp} className="return-btn">
              Return
            </button>
          </div>

        ) : mainMenu ? (
          <div className="rules-section">
            <h2>Game Rules</h2>
            <p>
              The goal of the game is to navigate from the Start article to the End
              article using only Wikipedia links. Click on links within the articles
              to move from one article to another. Try to reach the End article as
              quickly as possible!
            </p>
           {/*  <div className="difficulty-buttons">
              <button
                onClick={() => handleDifficultySelect('easy')}
                className="difficulty-btn"
              >
                Easy
              </button>
              <button
                onClick={() => handleDifficultySelect('medium')}
                className="difficulty-btn"
              >
                Medium
              </button>
              <button
                onClick={() => handleDifficultySelect('hard')}
                className="difficulty-btn"
              >
                Hard
              </button>
            </div> */}
            {lobbyId && (
              <div className="lobby-info">

                <h3>Players in Lobby:</h3>
                <ul>
                  {lobbyPlayers.map((player, index) => (
                    <li key={index}>
                      {player.username} {player.isHost && '(HOST)'}
                    </li>
                  ))}
                </ul>

    
              </div>
            )}
            {!lobbyId && (
              <>
                <div className="lobby-controls">
                  <button onClick={handleCreateLobby} className="difficulty-btn">
                    Create Lobby
                  </button>
                </div>
                {openLobbies.length > 0 && (
                  <div className="open-lobbies">
                    <h3>Open Lobbies:</h3>
                    <ul>
                      {openLobbies.map((lobby) => (
                        <li key={lobby.lobbyId}>
                          Host: {lobby.hostUsername} - Players: {lobby.playerCount} 
                          {lobby.gameStarted && '(GAME IN PROGRESS)'}
                          {!lobby.gameStarted && (
                            <>
                              <span> | Category: {lobby.selectedCategory}</span> {/* Display the selected category */}
                              <button
                                onClick={() => handleJoinLobby(lobby.lobbyId)}
                                className="difficulty-btn"
                              >
                                Join
                              </button>
                            </>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

              </>
            )}
            {lobbyId && (
              
              <button onClick={handleLeaveLobby} className="difficulty-btn">
                Leave Lobby
              </button>
              
            )}
            {isHost && ( 
  <div className="start-game-section">
    <button onClick={handleStartGameClick} className="difficulty-btn">
      {loading ? 'Loading...' : 'Start Game'}
    </button>
    <select
      id="category"
      className="select-category"
      value={selectedCategory}
      onChange={(e) => handleCategorySelect(e.target.value)}
    >
      <option value="">Any Category</option>
      {categories.map((category, index) => (
        <option key={index} value={category}>
          {category}
        </option>
      ))}
    </select>
  </div>
)}

          </div>
        ) : lobbyId && !showLoginForm && !showRegisterForm &&(
          <>
           <div
              className="article-section"
              onClick={!gameCompleted ? handleLinkClick : null}
            >
              {articleContent ?  (
                <>
                  <h2>{articleContent.title}</h2>
                  <div
                    className="article-content"
                    dangerouslySetInnerHTML={{
                      __html: articleContent.content,
                    }}
                  />
                </>
              ) : (
                <p>{loading ? 'Loading...' : 'No article found'}</p>
              )}
            </div>
        

            {!mainMenu && (
              <div className="game-controls">
              <div className="article-info">
              <div className="article-line">
                <strong>Start Article:</strong>
                <div className="article-title-with-tooltip">
                  <span>{startArticle}</span>
                  <span className="tooltip">
                    {startCategory && startCategory.length > 0 ? (
                      startCategory.map((category, index) => (
                        <span key={index}>
                          {category}{index < startCategory.length - 1 ? ', ' : ''}
                        </span>
                      ))
                    ) : (
                      <span>No category info yet!</span>
                    )}
                  </span>
                </div>
              </div>

              <div className="article-line">
                <strong>End Article:</strong>
                <div className="article-title-with-tooltip">
                  <span>{endArticle}</span>
                  <span className="tooltip">
                    {endCategory && endCategory.length > 0 ? (
                      endCategory.map((category, index) => (
                        <span key={index}>
                          {category}{index < endCategory.length - 1 ? ', ' : ''}
                        </span>
                      ))
                    ) : (
                      <span>No category info yet!</span>
                    )}
                  </span>
                </div>
              </div>




              </div>
                <p className="timer">
                  {`${time.minutes}:${time.seconds < 10 ? '0' : ''}${time.seconds}`}
                </p>
                <button onClick={handleGiveUp}>
                  {gameCompleted ? 'Return' : 'Give Up'}
                </button>
                <div className="lobby-players">
                  <h3>Players in Lobby:</h3>
                  <ul>
                    {lobbyPlayers.map((player, index) => (
                      <li key={index}>
                        {player.username} {player.isHost && '(HOST)'}
                      </li>
                    ))}
                  </ul>
                  
                </div>
                <div className="history">
                  <h3>History:</h3>
                  <ul>
                    {history.slice().reverse().map((item, index) => (
                      <li key={index}>
                        <span className="number">{history.length - index}.</span>
                        <span>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>


              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default App;
