import React, { useEffect, useState } from 'react';
import FetchArticle from './FetchArticle';

const MatchHistoryComponent = () => {
    const [matchHistory, setMatchHistory] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalMatches, setTotalMatches] = useState(0);
    const [expandedMatch, setExpandedMatch] = useState(null); // Track expanded match
    const [backgroundImage, setBackgroundImage] = useState(''); // Track background image for expanded match
    const matchesPerPage = 10;
  
    // Fetch match history from the server
    const fetchMatchHistory = async (page) => {
      const offset = (page - 1) * matchesPerPage;
      try {
        const response = await fetch(
          `https://wikiguessr-6d5a3c1aebf6.herokuapp.com/api/match-history?limit=${matchesPerPage}&offset=${offset}`
        );
        const data = await response.json();
  
        setMatchHistory(data.matches || []); // Ensure matches are always an array
        setTotalMatches(data.totalMatches || 0); // Correctly set total matches from API response
      } catch (error) {
        console.error('Error fetching match history:', error);
      }
    };
  
    useEffect(() => {
      fetchMatchHistory(currentPage);
    }, [currentPage]);
  
    const totalPages = Math.ceil(totalMatches / matchesPerPage);
  
    const fetchBackgroundImage = async (articleTitle) => {
      const articleData = await FetchArticle(articleTitle);
      setBackgroundImage(articleData.firstImageUrl);
    };

    const toggleMatchExpand = (match) => {
      if (expandedMatch === match.match_id) {
        setExpandedMatch(null);
        setBackgroundImage('');
      } else {
        setExpandedMatch(match.match_id);
        fetchBackgroundImage(match.start_article); // Fetch the image for start article
      }
    };
  
    return (
      <div>
        <h3>Match History</h3>
        <ul>
          {matchHistory.length > 0 ? (
            matchHistory.map((match) => (
              <li
                key={match.match_id}
                onClick={() => toggleMatchExpand(match.match_id)}
                className={expandedMatch === match.match_id ? 'expanded' : ''}
                style={{
                  backgroundImage: expandedMatch === match.match_id && backgroundImage ? `url(${backgroundImage})` : 'none',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              >
                <p><strong>Players:</strong> {JSON.parse(match.player_data).map((player) => player.username).join(', ')}</p>
                <p><strong>Winner:</strong> {match.winner_username}</p>
                <p><strong>Game Duration:</strong> {Math.floor(match.game_duration / 60)} mins {match.game_duration % 60} secs</p>
                
                {expandedMatch === match.match_id && (
                  <div className="expanded-content" style={{ marginTop: '10px', paddingLeft: '15px' }}>
                    <p><strong>Host:</strong> {match.host_username}</p>
                    <p><strong>Start Article:</strong> {match.start_article}</p>
                    <p><strong>End Article:</strong> {match.end_article}</p>
                    <p><strong>Game Start:</strong> {new Date(match.game_start).toLocaleString()}</p>
                    <p><strong>Player History:</strong></p>
                    <ul>
                      {JSON.parse(match.player_data).map((player, index) => (
                        <li key={index}>
                          <p><strong>{player.username}'s History:</strong></p>
                          <ul>
                            {player.history && player.history.length > 0 ? (
                              player.history.map((historyItem, idx) => (
                                <li key={idx}>{historyItem}</li>
                              ))
                            ) : (
                              <li>No history available.</li>
                            )}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            ))
          ) : (
            <p>No matches found.</p>
          )}
        </ul>
  
        {/* Pagination Controls */}
        <div className="pagination-controls">
          <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
            Previous
          </button>
          <span>Page {currentPage} of {totalPages}</span>
          <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages}>
            Next
          </button>
        </div>
      </div>
    );
  };

export default MatchHistoryComponent;
