import React, { useEffect, useState } from 'react';

const UsersComponent = () => {
    const [users, setUsers] = useState([]);
    const [expandedUserId, setExpandedUserId] = useState(null); // Track the expanded user
    const [currentPage, setCurrentPage] = useState(1);
    const [totalUsers, setTotalUsers] = useState(0);
    const usersPerPage = 30;
  
    // Fetch users from the server
    const fetchUsers = async (page) => {
      const offset = (page - 1) * usersPerPage;
      try {
        const response = await fetch(`https://wikiguessr-6d5a3c1aebf6.herokuapp.com/api/users?limit=${usersPerPage}&offset=${offset}`);
        const data = await response.json();
        setUsers(data.users || []);
        setTotalUsers(data.totalUsers || 0);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
  
    const removeUser = async (userId) => {
      try {
        const response = await fetch(`https://wikiguessr-6d5a3c1aebf6.herokuapp.com/api/users/${userId}`, {
          method: 'DELETE',
        });
  
        const data = await response.json();
        if (data.success) {
          fetchUsers(currentPage); // Refetch users after deletion
        } else {
          console.error('Error deleting user:', data.message);
        }
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    };
  
    // Toggle admin status and optimistically update the UI
    const toggleAdminStatus = async (userId, newAdminStatus) => {
      // Optimistically update the local state before API call
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === userId ? { ...user, admin: newAdminStatus ? 1 : 0 } : user
        )
      );
  
      try {
        const response = await fetch(`https://wikiguessr-6d5a3c1aebf6.herokuapp.com/api/users/${userId}/admin`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ admin: newAdminStatus ? 1 : 0 }),
        });
  
        const data = await response.json();
        if (!data.success) {
          console.error('Error updating admin status');
          fetchUsers(currentPage); // Fetch the data again in case of failure
        }
      } catch (error) {
        console.error('Error updating admin status:', error);
        fetchUsers(currentPage); // Re-fetch the data in case of API error
      }
    };
  
    const toggleUserExpand = (userId) => {
      if (expandedUserId === userId) {
        setExpandedUserId(null); // Collapse if already expanded
      } else {
        setExpandedUserId(userId); // Expand the clicked user
      }
    };
  
    useEffect(() => {
      fetchUsers(currentPage);
    }, [currentPage]);
  
    return (
      <div className="admin-panel">
        <h3>Users</h3>
        <ul>
          {users.map((user) => (
            <li
              key={user.id}
              onClick={() => toggleUserExpand(user.id)}
              className={expandedUserId === user.id ? 'expanded' : ''}
            >
              {/* Username aligned to the left */}
              <div className="username">
                <p><strong>Username:</strong> {user.username}</p>
              </div>
              
              {/* Email more centered */}
              <div className="email">
                <p><strong>Email:</strong> {user.email}</p>
              </div>
  
  
  
  
  
              {/* Render last sign-in if user is expanded */}
              {expandedUserId === user.id && (
                <div className="expanded-content">
                  <p><strong>Created:</strong> {user.created_at ? new Date(user.created_at).toLocaleDateString() : 'Error'}</p>
                  <p><strong>Last Sign In:</strong> {user.last_sign_in ? new Date(user.last_sign_in).toLocaleString('nb-NO', { timeZone: 'Europe/Oslo' }) : 'Never'}</p>
                  <p><strong>Email Verified:</strong> {user.email_verified ? 'Yes' : 'No'}</p>
                              {/* Admin checkbox */}
              <label>
                <input
                  type="checkbox"
                  checked={user.admin === 1} // Ensure the checkbox reflects the correct admin status
                  onChange={(e) => {
                    e.stopPropagation(); // Prevent expanding when toggling admin status
                    toggleAdminStatus(user.id, user.admin === 0); // Toggle between 0 and 1
                  }}
                />
                Admin
              </label>
                          {/* Remove button on the far right */}
                          <button
                onClick={(e) => {
                  e.stopPropagation(); // Prevent expanding when clicking "Remove"
                  removeUser(user.id);
                }}
              >
                Delete user
              </button>
                </div>
                
              )}
            </li>
          ))}
        </ul>
  
        {/* Pagination Controls */}
        <div className="pagination-controls">
          <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
            Previous
          </button>
          <span>Page {currentPage} of {Math.ceil(totalUsers / usersPerPage)}</span>
          <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === Math.ceil(totalUsers / usersPerPage)}>
            Next
          </button>
        </div>
      </div>
    );
  };
  
export default UsersComponent;  