const FetchArticle = async (title, setLoading) => {
    try {
      if (setLoading) setLoading(true); // Set loading state if setLoading is provided
      let response = await fetch(
        `https://en.wikipedia.org/w/api.php?action=parse&format=json&page=${encodeURIComponent(
          title
        )}&prop=text&redirects=true&origin=*`
      );
      let data = await response.json();
  
      // Check if the response has a redirect
      if (data.error?.code === 'missingtitle') {
        const redirectResponse = await fetch(
          `https://en.wikipedia.org/w/api.php?action=query&titles=${encodeURIComponent(
            title
          )}&redirects&format=json&origin=*`
        );
        const redirectData = await redirectResponse.json();
  
        const redirectedTitle = redirectData.query?.redirects?.[0]?.to;
  
        if (redirectedTitle) {
          // Re-fetch the article using the new title
          return await FetchArticle(redirectedTitle, setLoading);
        }
      }
  
      let content = data.parse.text['*'];
  
      // Create a temporary DOM element to parse the HTML and manipulate it
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = content;
  
      // Remove unwanted sections using IDs, classes, and heading-based elements
      const sectionsToRemove = [
        '#See_also', // "See also" section
        '#References', // "References" section
        '#External_links', // "External links" section
        '#Further_reading', // "Further reading" section
        '.reflist', // Reference list
        '.navbox', // Navigational boxes at the bottom
        '.hatnote', // Hatnotes or notes at the beginning
        '.mw-references-wrap', // Wrapper for references
        '.citation', // Citation notes in the text
        '.infobox', // Sidebar info boxes
        '.ambox', // Article message boxes, like "additional citations needed"
        '.tmbox', // Talk page message boxes
        '.cmbox', // Content message boxes
        '.fmbox', // Footer message boxes
        '.mw-editsection', // "Edit" links next to headings
        '.table.sidebar',
        '.table.vertical-navbox',
        '.div.navbox',
        '.div.metadata',
        '.table.infobox',
      ];
  
      // Remove the specified sections
      sectionsToRemove.forEach((selector) => {
        const elements = tempDiv.querySelectorAll(selector);
        elements.forEach((element) => element.remove());
      });
  
      // Remove all [note] and [citation] links
      const citationLinks = tempDiv.querySelectorAll('.reference, .mw-ref');
      citationLinks.forEach((link) => link.remove());
  
      // Optionally, remove any sections by matching specific heading titles dynamically
      const headlineTextsToRemove = ['See also', 'References', 'External links', 'Further reading', 'Notes'];
      headlineTextsToRemove.forEach((text) => {
        const headings = tempDiv.querySelectorAll('h2, h3, h4, h5, h6');
        headings.forEach((heading) => {
          if (heading.textContent.trim().toLowerCase() === text.toLowerCase()) {
            let currentElement = heading;
            while (currentElement && currentElement.nextElementSibling) {
              if (/^H[2-6]$/.test(currentElement.nextElementSibling.tagName)) break;
              currentElement.nextElementSibling.remove();
            }
            heading.remove();
          }
        });
      });
  
      // Extract the first image URL
      const firstImage = tempDiv.querySelector('img');
      let firstImageUrl = '';
      if (firstImage) {
        firstImageUrl = firstImage.src;
        // Wikipedia's image links are often relative, fix if needed
        if (firstImageUrl.startsWith('//')) {
          firstImageUrl = 'https:' + firstImageUrl;
        }
      }
  
      return {
        title: title.replace(/_/g, ' '),
        content: tempDiv.innerHTML,
        firstImageUrl: firstImageUrl,
        error: false,
      };
    } catch (error) {
      return {
        title: 'Error',
        content: 'An error occurred while fetching data.',
        error: true,
      };
    } finally {
      if (setLoading) setLoading(false); // Set loading state if setLoading is provided
    }
  };
  
  export default FetchArticle;
  