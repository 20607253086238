import React, { useEffect, useState } from 'react';

const ArticlesComponent = () => {
    const [articles, setArticles] = useState([]); // Initialize as an empty array
    const [currentPage, setCurrentPage] = useState(1);
    const [totalArticles, setTotalArticles] = useState(0);
    const [newArticle, setNewArticle] = useState('');
    const [expandedArticle, setExpandedArticle] = useState(null); // Track the expanded article
    const [assignedCategories, setAssignedCategories] = useState({});
    const [categories, setCategories] = useState([]); // List of all available categories
    const [selectedCategory, setSelectedCategory] = useState(''); // Category to assign to the article
    const [expandedCategories, setExpandedCategories] = useState({}); // Track expanded categories within articles
    const [categoryArticles, setCategoryArticles] = useState({}); // Store articles for each category
    const perPage = 20;
  
    // Fetch articles, categories, assigned categories, etc.
    const fetchArticles = async (page) => {
      const offset = (page - 1) * perPage;
      try {
        const response = await fetch(`https://wikiguessr-6d5a3c1aebf6.herokuapp.com/api/articles?limit=${perPage}&offset=${offset}`);
        const data = await response.json();
        setArticles(data.articles || []);
        setTotalArticles(data.totalArticles || 0);
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    };
  
    const fetchCategories = async () => {
      try {
        const response = await fetch('https://wikiguessr-6d5a3c1aebf6.herokuapp.com/api/categories'); // Fetch all categories
        const data = await response.json();
        if (data.categories) {
          setCategories(data.categories);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
  
    const fetchAssignedCategories = async (articleId) => {
      try {
        const response = await fetch(`https://wikiguessr-6d5a3c1aebf6.herokuapp.com/api/article-categories/${articleId}`);
        const data = await response.json();
        if (data.success) {
          setAssignedCategories((prev) => ({
            ...prev,
            [articleId]: data.categories,
          }));
        }
      } catch (error) {
        console.error('Error fetching assigned categories:', error);
      }
    };
  
    const assignCategoryToArticle = async (articleId) => {
      if (!selectedCategory) {
        alert('Please select a category to assign.');
        return;
      }
  
      try {
        const response = await fetch('https://wikiguessr-6d5a3c1aebf6.herokuapp.com/api/assign-category', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            articleId,
            categoryId: selectedCategory,
          }),
        });
  
        if (response.ok) {
          alert('Category assigned successfully!');
          fetchAssignedCategories(articleId); // Update the assigned categories for this article
        } else {
          alert('Failed to assign category.');
        }
      } catch (error) {
        console.error('Error assigning category to article:', error);
        alert('Error assigning category.');
      }
    };
  
    const handleCategoryRemove = async (articleId, categoryId) => {
      try {
        const response = await fetch('https://wikiguessr-6d5a3c1aebf6.herokuapp.com/api/remove-article-from-category', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            articleId,
            categoryId,
          }),
        });
  
        if (response.ok) {
          alert('Category removed successfully!');
          fetchAssignedCategories(articleId); // Update the assigned categories for this article
        } else {
          alert('Failed to remove category.');
        }
      } catch (error) {
        console.error('Error removing category from article:', error);
        alert('Error removing category.');
      }
    };
  
    const toggleArticleExpand = (articleId) => {
      if (expandedArticle === articleId) {
        setExpandedArticle(null); // Collapse if already expanded
      } else {
        setExpandedArticle(articleId); // Expand the clicked article
        fetchAssignedCategories(articleId); // Fetch assigned categories for this article
      }
    };
  
    const toggleCategoryExpand = (articleId, categoryId) => {
      setExpandedCategories((prev) => ({
        ...prev,
        [articleId]: prev[articleId] === categoryId ? null : categoryId,
      }));
    };
  
    const addArticle = async () => {
      if (newArticle.trim() === '') {
        alert('Article name cannot be empty');
        return;
      }
      try {
        const response = await fetch('https://wikiguessr-6d5a3c1aebf6.herokuapp.com/api/articles', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ name: newArticle }),
        });
  
        const data = await response.json();
        if (data.success) {
          setNewArticle(''); // Clear input after adding
          fetchArticles(); // Refetch articles after adding a new one
        } else {
          console.error('Error adding article:', data.message);
        }
      } catch (error) {
        console.error('Error adding article:', error);
      }
    };
  
    const removeArticle = async (articleId) => {
      try {
        const response = await fetch(`https://wikiguessr-6d5a3c1aebf6.herokuapp.com/api/articles/${articleId}`, {
          method: 'DELETE',
        });
        const data = await response.json();
        if (data.success) {
          fetchArticles(); // Refetch articles after deletion
        } else {
          console.error('Error deleting article:', data.message);
        }
      } catch (error) {
        console.error('Error deleting article:', error);
      }
    };
  
    useEffect(() => {
      fetchArticles(currentPage);
      fetchCategories(); // Fetch categories when the component mounts
    }, [currentPage]);
  
    return (
      <div>
        <h3>Articles</h3>
        <div className="add-category">
          <input
            type="text"
            value={newArticle}
            onChange={(e) => setNewArticle(e.target.value)}
            placeholder="Enter article name"
          />
          <button onClick={addArticle}>Add Article</button>
        </div>
        <ul>
          {articles.length > 0 ? (
            articles.map((article) => (
              <li
                key={article.id}
                onClick={() => toggleArticleExpand(article.id)}
                className={expandedArticle === article.id ? 'expanded' : ''}
              >
                <p><strong>Article:</strong> {article.title}</p>
                {expandedArticle === article.id && (
                  <div className="expanded-content">
                    <ul>
                      <p><strong>Assigned Categories:</strong></p>
                      {assignedCategories[article.id] && assignedCategories[article.id].length > 0 ? (
                        assignedCategories[article.id].map((category) => (
                          <li
                            key={category.id}
                            onClick={(e) => {
                              e.stopPropagation();
                              toggleCategoryExpand(article.id, category.id);
                            }}
                            className={expandedCategories[article.id] === category.id ? 'expanded-category' : ''}
                          >
                            {category.category_name}
                            {expandedCategories[article.id] === category.id && (
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleCategoryRemove(article.id, category.id);
                                }}
                              >
                                Remove
                              </button>
                            )}
                          </li>
                        ))
                      ) : (
                        <li>No categories assigned.</li>
                      )}
                    </ul>
                    <div className="assign-category">
                      <select
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <option value="">Select Category</option>
                        {categories.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.category_name}
                          </option>
                        ))}
                      </select>
                      <button onClick={(e) => {
                        e.stopPropagation();
                        assignCategoryToArticle(article.id);
                      }}>
                        Assign Category
                      </button>
                    </div>
                  </div>
                )}
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    removeArticle(article.id);
                  }}
                >
                  Remove
                </button>
              </li>
            ))
          ) : (
            <p>No articles found.</p>
          )}
        </ul>
        <div className="pagination-controls">
          <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
            Previous
          </button>
          <span>Page {currentPage} of {Math.ceil(totalArticles / perPage)}</span>
          <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === Math.ceil(totalArticles / perPage)}>
            Next
          </button>
        </div>
      </div>
    );
  };
export default ArticlesComponent;